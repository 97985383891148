import { render, staticRenderFns } from "./DialogSendReminderEmail.vue?vue&type=template&id=55965810&scoped=true&"
import script from "./DialogSendReminderEmail.vue?vue&type=script&lang=js&"
export * from "./DialogSendReminderEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55965810",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chris/IdeaProjects/portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55965810')) {
      api.createRecord('55965810', component.options)
    } else {
      api.reload('55965810', component.options)
    }
    module.hot.accept("./DialogSendReminderEmail.vue?vue&type=template&id=55965810&scoped=true&", function () {
      api.rerender('55965810', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/finance/components/DialogSendReminderEmail.vue"
export default component.exports